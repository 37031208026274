<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useListTable from '@/comp-functions/useListTable';
import moment from 'moment'
import DatePicker from "vue2-datepicker";
import { ref, onMounted } from '@vue/composition-api'
import {formatNumber} from '@/utils/formatter'
import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Billing List",
    meta: [{ name: "description", content: appConfig.description }]
  },
  setup () {
     const { 
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists } = useListTable({ url: false })

    
    const tableColumns = [
      { key: "inv_number", label: 'Invoice#', thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "ordno", label: "Order#", thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "inv_date", label: "Date", thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "vnd_name", label: "Airlines", thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "sch_flight_no", label: "Fligh No", thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "schroute", label: "Route", thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "inv_gtotalcharge", label: "Grand Total", thClass: 'text-right', tdClass: 'text-right', sortable: true,
        formatter: value => {return formatNumber(value)}
      },
      { key: "status", label: "Payment Status", thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "action", thClass: 'text-center', tdClass: 'text-center',label: "#" },
    ]
    
    // Table Handlers
    const columnToBeFilter = [
      'inv_number',
      'order_id',
      'ord_no',
      'inv_date',
      'vendor_id',
      'schedule_id',
      'route_id',
      'inv_gtotalcharge'
    ]

    const date1 = new Date()
    const date2 = new Date()
    date1.setDate(date2.getDate() - 23);
    const paidstatus = ref()
    const paramdate = ref([moment(date1).format('DD-MM-YYYY'), moment(date2).format('DD-MM-YYYY')])
    const reloaddatalist = async () => {
      let url = '/report/invoice'
      let params = {
        start_invdate: paramdate.value[0],
        end_invdate:  paramdate.value[1],
        paid_status: paidstatus.value
      }
      await fetchLists(url,params)
    }
    onMounted(() => {
      reloaddatalist()
    })
    return {
      formatNumber,
      select_label: "Select",
      tableColumns,
      columnToBeFilter,
      paramdate,
      paidstatus,
      reloaddatalist,

      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    }
  },
    methods: {
      onFiltered(filteredItems) {
        this.totalList = filteredItems.length;
        this.currentPage = 1;
      }
    },
    components: { Layout, PageHeader, DatePicker, Multiselect },
  data() {
    return {
      title: "Billing List",
      items: [],
      filterOn: []
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
               <div class="col-sm-12 col-md-5">
                <label class="col-md-12 col-sm-12 d-inline-flex align-items-center p-0">
                  Invoice Date :
                  <date-picker class="col-md-8" @close="reloaddatalist()"  v-model="paramdate" format='DD-MM-YYYY' valueType="format"  range append-to-body lang="en"></date-picker>
                </label>
                </div>
              <div class="col-sm-12 col-md-2">
                <multiselect v-model="paidstatus" :options="['PAID', 'UNPAID','EXPIRED']"  @close="reloaddatalist()" :select-label="select_label"></multiselect>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-5">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="searchQuery"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                id="refListTable"
                ref="refListTable"
                no-provider-paging
                :fields="tableColumns"
                :items="itemLists"
                :per-page="perPage"
                :current-page="currentPage"
                :filter="searchQuery"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                responsive
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                :busy="isBusy"
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
                class="position-relative font-size-13"
              >
               <template #cell(ordno)="data">
                  <router-link :to="{ name: 'apps-order-detail', params: { p_ordno: data.item.ord_no }}">{{data.item.ord_no}}</router-link>
              </template>
               <template #cell(schroute)="data">
                  {{ data.item. orig_prt_initial }} - {{ data.item.dest_prt_initial }}
                </template>
                
               <template #cell(status)="data">
                  <div v-if="data.item.pay_inv_id" class="badge badge-soft-success font-size-13">
                    PAID
                  </div>
                  <div v-else-if="data.item.is_expired" class="badge badge-soft-warning font-size-13">
                      EXPIRED
                  </div>
                  <div v-else-if="!data.item.pay_inv_id && !data.item.is_expired" class="badge badge-soft-danger font-size-13">
                    UNPAID
                  </div>
                </template>

                <template v-slot:cell(action)="data">
                  <router-link 
                    v-if="!data.item.pay_inv_id && !data.item.is_expired"
                    tag="a"
                    :to="{name: 'Payment Confirmation Add', params: {invoice_id : data.item.inv_id}}"
                    class="btn btn-sm btn-primary"
                    v-b-tooltip.hover title="Payment Confirmation"
                  >Payment Confirmation</router-link>
                </template>
              </b-table>
            </div>
            <div class="row">
               <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                   Showing
                    <b-form-select class="col-sm-12 col-md-2" v-model="perPage" size="sm" :options="perPageOptions"></b-form-select>{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
                  </label>
                </div>
              </div>
              <div class="col-md-6 col-xs-12">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalList" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
